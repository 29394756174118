import { Base64 } from "js-base64";

/**
 * Converts a string into a Uint8Array
 */
export const stringToUint8Array = (str: string): Uint8Array => {
    return Uint8Array.from(
        atob(str.replace(/_/g, "/").replace(/-/g, "+")),
        (c) => c.charCodeAt(0),
    );
};

export const arrayBufToB64 = (arr: ArrayBuffer): string => {
    return Base64.fromUint8Array(new Uint8Array(arr));
};
