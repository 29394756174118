// Load polyfills needed for IE11 and other old browsers
import "core-js/stable";
import "regenerator-runtime/runtime";

// Load custom styles
import "./main.scss";

// Load bootstrap
import "bootstrap";

// Sentry
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://f335933410f447d1a216e5180afb23b2@o19836.ingest.sentry.io/1426294",
    // Only send 0.05% of transaction traces to Sentry (to stay within
    // our 250k traces/month limit).
    tracesSampleRate: 0.0005,
});

// Load Modules
import "./webauthn/register";
import "./webauthn/verify";
