import { stringToUint8Array, arrayBufToB64 } from "./_utils";

/**
 * Transforms the credential creation option JSON from the server into something the AuthN API can work with.
 */
const transformCredentialCreateOptions = (
    jsonFromServer: string,
): PublicKeyCredentialCreationOptions => {
    const fromServer = JSON.parse(jsonFromServer);
    const credCreateOptions: PublicKeyCredentialCreationOptions = {
        ...fromServer,
        challenge: stringToUint8Array(fromServer.challenge),
        user: {
            ...fromServer.user,
            id: stringToUint8Array(fromServer.user.id),
        },
    };
    return credCreateOptions;
};

/**
 * Transforms the binary data in the credential into base64 strings for posting to the server.
 */
const transformNewAssertionForServer = (newAssertion: PublicKeyCredential) => {
    const response = newAssertion.response as AuthenticatorAttestationResponse;
    return {
        id: newAssertion.id,
        rawId: arrayBufToB64(newAssertion.rawId),
        response: {
            attestationObject: arrayBufToB64(response.attestationObject),
            clientDataJSON: arrayBufToB64(response.clientDataJSON),
            transports: response.getTransports ? response.getTransports() : [],
        },
        type: newAssertion.type,
        clientExtensionResults: newAssertion.getClientExtensionResults(),
        authenticatorAttachment: newAssertion.authenticatorAttachment,
    };
};

/**
 * Sign a challenge and post the data back to the server
 */
const registerToken = async (formElem: HTMLFormElement) => {
    // Transform input data
    const optionsJSON = formElem.dataset.options;
    if (!optionsJSON) {
        throw new Error("Missing data-options");
    }
    const makeCredOptions = transformCredentialCreateOptions(optionsJSON);
    // Sign the challenge
    const creds = (await navigator.credentials.create({
        publicKey: makeCredOptions,
    })) as PublicKeyCredential;
    // Serialize the credentials and insert them into the form
    const credsForServer = transformNewAssertionForServer(creds);
    const input =
        formElem.querySelector<HTMLInputElement>("#id_assertion_data");
    if (!input) {
        throw new Error("Missing #id_assertion_data");
    }
    input.value = JSON.stringify(credsForServer);
    // Allow the user to name the key
    formElem
        .querySelector<HTMLElement>(".waiting-for-key")!
        .classList.add("waiting-for-key--hidden");
    formElem
        .querySelector<HTMLElement>(".name-key")!
        .classList.remove("name-key--hidden");
};

/**
 * Wait for the user to press the form button, then start the registration process.
 */
const startRegistration = () => {
    const _formElem = document.querySelector<HTMLFormElement>(
        "#webauthn-add-token",
    );
    if (!_formElem) {
        return;
    }
    // Progress to next step
    registerToken(_formElem);
    document
        .querySelector<HTMLElement>(".waiting-for-user-action")!
        .classList.add("waiting-for-user-action--hidden");
    document
        .querySelector<HTMLElement>(".waiting-for-key")!
        .classList.remove("waiting-for-key--hidden");
};

document
    .querySelector<HTMLElement>(".waiting-for-user-action button")
    ?.addEventListener("click", startRegistration);
